import FaqAccordionSection from "../components/sections/FaqAccordionSection/FaqAccordionSection"
import IllustrationHeader from "../components/global/IllustrationHeader"
import Layout from "../components/global/Layout"
import React from "react"
import TwoCol_AccountBreakdownSection from "../components/page-specific/Get-Started/TwoCol_AccountBreakdownSection/TwoCol_AccountBreakdownSection"
import GHPricingBreakdown from "../components/page-specific/Corporate-Accounts-Pricing/GHPricingBreakdown/GHPricingBreakdown";
import GHCtaSection from "../components/sections/GHCtaSection/GHCtaSection";
import GHFaqAccordionSection from "../components/sections/GHFaqAccordionSection/GHFaqAccordionSection";
import GHValuePropsCustomBox from "../components/sections/GHValuePropsCustomBox/GHValuePropsCustomBox";
import GHGeneralHeader from "../components/global/GHGeneralHeader";
import GHLayout from "../components/global/GHLayout/GHLayout";

const CorporateAccountsPricing = ( { location } ) => {

    const imageSizesValueProps = {
        maxWidth: "225px",
        maxHeight: "173px"
    }
    const imageSizesMealProps = {
        maxWidth: "78px",
        maxHeight: "78px"
    }


    const valueProps = [
        {
            headline: "Stretch meal credits",
            subheadline: "$0 delivery fees help employees get the most out of their meal credits.",
            imageSrc: "/images/pages/grubhub-plus/stretch_meal_credits.svg",
            imageSize: imageSizesValueProps,
        },
        {
            headline: "Order from anywere",
            subheadline: "Grubhub+ can be used in the office or at home—on or off the clock.",
            imageSrc: "/images/pages/grubhub-plus/order_from_anywhere.png",
            imageSize: imageSizesValueProps,
        },
        {
            headline: "Give back",
            subheadline: "When employees order on their dime and donate the change, we'll match it in support of the Grubhub Community Fund up to a predetermined amount each year.**",
            imageSrc: "/images/pages/grubhub-plus/give_back.png",
            imageSize: imageSizesValueProps,
        },
    ]

    const valuePropsMeals = [
        {
            headline: "Unlimited $0  delivery fees",
            subheadline: "Enjoy on eligible orders.",
            imageSrc: "/images/pages/grubhub-plus/unlimited_0_delivery_fees_scooter.svg",
            imageSize: imageSizesMealProps,
        },
        {
            headline: "5% credit  back on pickup",
            subheadline: "Earn Grubhub+ Credit for use on future orders.",
            imageSrc: "/images/pages/grubhub-plus/5_credit_back_on_pickup.png",
            imageSize: imageSizesMealProps,
        },
        {
            headline: "Lower service fees",
            subheadline: "Save even more on  eligible orders.",
            imageSrc: "/images/pages/grubhub-plus/lower_services_fees.png",
            imageSize: imageSizesMealProps,
        },
        {
            headline: "Exclusive offers",
            subheadline: "Get tasty deals and more for local restaurants and stores.",
            imageSrc: "/images/pages/grubhub-plus/exclusive_offers.png",
            imageSize: imageSizesMealProps,
        },
    ]

    const cards = [
        {
            title: "Individual Meals",
            description: "Employees can order meals to the office, home or wherever they’re working—you set the budget and control spend.",
            link: "/individual-meals/",
            imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626794994/Grubhub%20Corporate%20Website/2021/Photography%20Elements/1-WaysToOrder-Homepage/EXPORTS-Photography-WaysToOrder-Homepage-IndividualMeals.jpg",
            linkText: "Learn more"
        },
        {
            title: "Group Ordering",
            description: "Send out restaurant choices and let your team order what they want. Or, let employees add food to a shared cart. Any way you order, meals are delivered together and everyone gets something they love.",
            link: "/group-orders/",
            imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797393/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-GroupOrdering.jpg",
            linkText: "Learn more"
        }
    ]

    return (
        <GHLayout
            location={location}
            title={"Grubhub Plus for Employees"}
            description="Offer exclusive perks, like 5% back on pickup orders, and tasty meals for your employees with Grubhub Plus."
            utag_data={{
                pageGroup: "grubhub - lead generation",
                subGroup: "two ways to get started",
                pageName: "two ways to get started",
                brand: "grubhub"
            }}
        >
            <GHGeneralHeader
                headlineMaxWidth={"540px"}
                headline="Treat your team to Grubhub+"
                subheadline="Give your employees unlimited $0 delivery fees, 5% credit back on pickup, and more.*"
                subheadlineStyle={{ marginTop: "16px", maxWidth: "390px" }}
                footnote="*Benefits apply to orders that meet the applicable minimum subtotal only. Terms and additional fees apply."
                footnoteStyle={{ maxWidth: "332px"}}
                textColor="#242E30"
                background="rgba(16,143,87,0.1)"
                backgroundColorForm='#FFF'
                type="full-image"//"half-image"
                alt="Grubhub delivery driver walking carrying two orange food bags."
                imageSrc="/images/pages/grubhub-plus/grubhub_plus_hero_background.png"
                imageSrcMobile="/images/pages/grubhub-plus/grubhub_plus_hero_background_mobile.png"
                mobileHeight={"1300px"}
                ctaLink={"/get-started"}
                ctaText={"Get started"}
                ctaStyle={{ marginRight: "auto" }}
            />
       <GHValuePropsCustomBox
           headline="A tasty world of premium perks"
           subheadline="Grubhub+ is a delicious addition to employee benefits packages."
           textColor={"#ffffff"}
           backgroundColor={"#ff8800"}
           valueProps={valueProps}
           layout="vertical"
           theme="catering"
           maxWidth="1024px"
           bottomGraphic={true}
           bottomGraphicNegativeColor={"#F5F3F1"}
           style={{paddingBottom: "100px"}}
       />
      <GHValuePropsCustomBox
          headline="Saving never tasted so good"
          textColor={"#242E30"}
          backgroundColor={"#F5F3F1"}
          valueProps={valuePropsMeals}
          layout="vertical"
          theme="catering"
          maxWidth="1024px"
          propsPerRow={4}
          maxPropWidth="208px"
      />

      <GHCtaSection
          headline="Upgrade your team’s benefits today"
          //subheadline="Contact your Grubhub Corporate Accounts rep today to get Grubhub+."
          ctaText="Get started"
          to="/contact-us/"
          buttonType={"secondary"}
          legal="**Grubhub will donate amounts equal to participating Grubhub+ order donations donated through Grubhub’s Donate the Change feature in connection with orders placed at Grubhub+ eligible restaurants only, up to a total of $4 million in donation matching per calendar year. Line of credit may not be used to make Donate the Change donations."
          backgroundColor="#FFFAEC"
          maxWidth="548px"
          topSpacing={"0px"}
          bottomSpacing={"0px"}
          topSpacingMobile={"0"}
          bottomSpacingMobile={"0"}
          graphicLeftSrc={"/images/pages/grubhub-plus/left_pluses.png"}
          graphicRightSrc={"/images/pages/grubhub-plus/right_pluses.png"}
          hideGraphicsMobile={true}
      />
    </GHLayout>
    )
}

export default CorporateAccountsPricing
